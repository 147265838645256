import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "../sidebar/sidebar.service";

import * as $ from 'jquery';
import { LoginService } from '../../../../src/app/core/services/login.service';
import { TappalserviceService } from '../../../../src/app/dashboard/tappal/tappalservice.service';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    login_type: any;
    // constructor(private http: HttpClient, private router: Router) {

    // }

    public menuItems: any[] = [
        {
            id: 1,
            label: 'MENUITEMS.MENU.TEXT',
            isTitle: true
        },
        {
            id: 2,
            label: 'MENUITEMS.DASHBOARDS.TEXT',
            icon: 'bx-home-circle',
            badge: {
                variant: 'info',
                text: 'MENUITEMS.DASHBOARDS.BADGE',
            },
            subItems: [
                {
                    id: 3,
                    label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
                    link: '/dashboard',
                    parentId: 2
                },
                {
                    id: 4,
                    label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
                    link: '/dashboards/saas',
                    parentId: 2
                },
                {
                    id: 5,
                    label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
                    link: '/dashboards/crypto',
                    parentId: 2
                },
                {
                    id: 6,
                    label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
                    link: '/dashboards/blog',
                    parentId: 2
                },
            ]
        },
        {
            id: 7,
            isLayout: true
        },
        {
            id: 8,
            label: 'MENUITEMS.APPS.TEXT',
            isTitle: true
        },
        {
            id: 9,
            label: 'MENUITEMS.CALENDAR.TEXT',
            icon: 'bx-calendar',
            link: '/calendar',
        },
        {
            id: 10,
            label: 'MENUITEMS.CHAT.TEXT',
            icon: 'bx-chat',
            link: '/chat',

        },
        {
            id: 11,
            label: 'MENUITEMS.FILEMANAGER.TEXT',
            icon: 'bx-file',
            link: '/filemanager',
            badge: {
                variant: 'success',
                text: 'MENUITEMS.FILEMANAGER.BADGE',
            },
        },
        {
            id: 12,
            label: 'MENUITEMS.ECOMMERCE.TEXT',
            icon: 'bx-store',
            subItems: [
                {
                    id: 13,
                    label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
                    link: '/ecommerce/products',
                    parentId: 12
                },
                {
                    id: 14,
                    label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
                    link: '/ecommerce/product-detail/1',
                    parentId: 12
                },
                {
                    id: 15,
                    label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
                    link: '/ecommerce/orders',
                    parentId: 12
                },
                {
                    id: 16,
                    label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
                    link: '/ecommerce/customers',
                    parentId: 12
                },
                {
                    id: 17,
                    label: 'MENUITEMS.ECOMMERCE.LIST.CART',
                    link: '/ecommerce/cart',
                    parentId: 12
                },
                {
                    id: 18,
                    label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
                    link: '/ecommerce/checkout',
                    parentId: 12
                },
                {
                    id: 19,
                    label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
                    link: '/ecommerce/shops',
                    parentId: 12
                },
                {
                    id: 20,
                    label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
                    link: '/ecommerce/add-product',
                    parentId: 12
                },
            ]
        },
        {
            id: 21,
            label: 'MENUITEMS.CRYPTO.TEXT',
            icon: 'bx-bitcoin',
            subItems: [
                {
                    id: 22,
                    label: 'MENUITEMS.CRYPTO.LIST.WALLET',
                    link: '/crypto/wallet',
                    parentId: 21
                },
                {
                    id: 23,
                    label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
                    link: '/crypto/buy-sell',
                    parentId: 21
                },
                {
                    id: 24,
                    label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
                    link: '/crypto/exchange',
                    parentId: 21
                },
                {
                    id: 25,
                    label: 'MENUITEMS.CRYPTO.LIST.LENDING',
                    link: '/crypto/lending',
                    parentId: 21
                },
                {
                    id: 26,
                    label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
                    link: '/crypto/orders',
                    parentId: 21
                },
                {
                    id: 27,
                    label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
                    link: '/crypto/kyc-application',
                    parentId: 21
                },
                {
                    id: 28,
                    label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
                    link: '/crypto-ico-landing',
                    parentId: 21
                }
            ]
        },
        {
            id: 29,
            label: 'MENUITEMS.EMAIL.TEXT',
            icon: 'bx-envelope',
            subItems: [
                {
                    id: 30,
                    label: 'MENUITEMS.EMAIL.LIST.INBOX',
                    link: '/email/inbox',
                    parentId: 29
                },
                {
                    id: 31,
                    label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
                    link: '/email/read/1',
                    parentId: 29
                },
                {
                    id: 32,
                    label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.TEXT',
                    badge: {
                        variant: 'success',
                        text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
                    },
                    parentId: 29,
                    subItems: [
                        {
                            id: 33,
                            label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BASIC',
                            link: '/email/basic',
                            parentId: 32
                        },
                        {
                            id: 34,
                            label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.ALERT',
                            link: '/email/alert',
                            parentId: 32
                        },
                        {
                            id: 35,
                            label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BILLING',
                            link: '/email/billing',
                            parentId: 32
                        }
                    ]
                }
            ]
        },
        {
            id: 36,
            label: 'MENUITEMS.INVOICES.TEXT',
            icon: 'bx-receipt',
            subItems: [
                {
                    id: 37,
                    label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
                    link: '/invoices/list',
                    parentId: 36
                },
                {
                    id: 38,
                    label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
                    link: '/invoices/detail',
                    parentId: 36
                },
            ]
        },
        {
            id: 39,
            label: 'MENUITEMS.PROJECTS.TEXT',
            icon: 'bx-briefcase-alt-2',
            subItems: [
                {
                    id: 40,
                    label: 'MENUITEMS.PROJECTS.LIST.GRID',
                    link: '/projects/grid',
                    parentId: 38
                },
                {
                    id: 41,
                    label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
                    link: '/projects/list',
                    parentId: 38
                },
                {
                    id: 42,
                    label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
                    link: '/projects/overview',
                    parentId: 38
                },
                {
                    id: 43,
                    label: 'MENUITEMS.PROJECTS.LIST.CREATE',
                    link: '/projects/create',
                    parentId: 38
                }
            ]
        },
        {
            id: 44,
            label: 'MENUITEMS.TASKS.TEXT',
            icon: 'bx-task',
            subItems: [
                {
                    id: 45,
                    label: 'MENUITEMS.TASKS.LIST.TASKLIST',
                    link: '/tasks/list',
                    parentId: 44
                },
                {
                    id: 46,
                    label: 'MENUITEMS.TASKS.LIST.KANBAN',
                    link: '/tasks/kanban',
                    parentId: 44
                },
                {
                    id: 47,
                    label: 'MENUITEMS.TASKS.LIST.CREATETASK',
                    link: '/tasks/create',
                    parentId: 44
                }
            ]
        },
        {
            id: 48,
            label: 'MENUITEMS.CONTACTS.TEXT',
            icon: 'bxs-user-detail',
            subItems: [
                {
                    id: 49,
                    label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
                    link: '/contacts/grid',
                    parentId: 48
                },
                {
                    id: 50,
                    label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
                    link: '/contacts/list',
                    parentId: 48
                },
                {
                    id: 51,
                    label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
                    link: '/contacts/profile',
                    parentId: 48
                }
            ]
        },
        {
            id: 52,
            label: 'MENUITEMS.BLOG.TEXT',
            icon: 'bx-file',
            badge: {
                variant: 'success',
                text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
            },
            subItems: [
                {
                    id: 53,
                    label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
                    link: '/blog/list',
                    parentId: 52
                },
                {
                    id: 54,
                    label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
                    link: '/blog/grid',
                    parentId: 52
                },
                {
                    id: 55,
                    label: 'MENUITEMS.BLOG.LIST.DETAIL',
                    link: '/blog/detail',
                    parentId: 52
                },
            ]
        },
        {
            id: 56,
            label: 'MENUITEMS.PAGES.TEXT',
            isTitle: true
        },
        {
            id: 57,
            label: 'MENUITEMS.AUTHENTICATION.TEXT',
            icon: 'bx-user-circle',
            badge: {
                variant: 'success',
                text: 'MENUITEMS.AUTHENTICATION.BADGE',
            },
            subItems: [
                {
                    id: 58,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
                    link: '/account/login',
                    parentId: 57
                },
                {
                    id: 59,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
                    link: '/account/login-2',
                    parentId: 57
                },
                {
                    id: 60,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
                    link: '/account/signup',
                    parentId: 57
                },
                {
                    id: 61,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
                    link: '/account/signup-2',
                    parentId: 57
                },
                {
                    id: 62,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
                    link: '/account/reset-password',
                    parentId: 57
                },
                {
                    id: 63,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
                    link: '/account/recoverpwd-2',
                    parentId: 57
                },
                {
                    id: 64,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
                    link: '/pages/lock-screen-1',
                    parentId: 57
                },
                {
                    id: 65,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
                    link: '/pages/lock-screen-2',
                    parentId: 57
                },
                {
                    id: 66,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
                    link: '/pages/confirm-mail',
                    parentId: 57
                },
                {
                    id: 67,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
                    link: '/pages/confirm-mail-2',
                    parentId: 57
                },
                {
                    id: 68,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
                    link: '/pages/email-verification',
                    parentId: 57
                },
                {
                    id: 69,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
                    link: '/pages/email-verification-2',
                    parentId: 57
                },
                {
                    id: 70,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
                    link: '/pages/two-step-verification',
                    parentId: 57
                },
                {
                    id: 71,
                    label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
                    link: '/pages/two-step-verification-2',
                    parentId: 57
                }
            ]
        },
        {
            id: 72,
            label: 'MENUITEMS.UTILITY.TEXT',
            icon: 'bx-file',
            subItems: [
                {
                    id: 73,
                    label: 'MENUITEMS.UTILITY.LIST.STARTER',
                    link: '/pages/starter',
                    parentId: 72
                },
                {
                    id: 74,
                    label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
                    link: '/pages/maintenance',
                    parentId: 72
                },
                {
                    id: 74,
                    label: 'Coming Soon',
                    link: '/pages/coming-soon',
                    parentId: 72
                },
                {
                    id: 75,
                    label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
                    link: '/pages/timeline',
                    parentId: 72
                },
                {
                    id: 76,
                    label: 'MENUITEMS.UTILITY.LIST.FAQS',
                    link: '/pages/faqs',
                    parentId: 72
                },
                {
                    id: 77,
                    label: 'MENUITEMS.UTILITY.LIST.PRICING',
                    link: '/pages/pricing',
                    parentId: 72
                },
                {
                    id: 78,
                    label: 'MENUITEMS.UTILITY.LIST.ERROR404',
                    link: '/pages/404',
                    parentId: 72
                },
                {
                    id: 79,
                    label: 'MENUITEMS.UTILITY.LIST.ERROR500',
                    link: '/pages/500',
                    parentId: 72
                },
            ]
        },
        {
            id: 80,
            label: 'MENUITEMS.COMPONENTS.TEXT',
            isTitle: true
        },
        {
            id: 81,
            label: 'MENUITEMS.UIELEMENTS.TEXT',
            icon: 'bx-tone',
            subItems: [
                {
                    id: 82,
                    label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
                    link: '/ui/alerts',
                    parentId: 81
                },
                {
                    id: 83,
                    label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
                    link: '/ui/buttons',
                    parentId: 81
                },
                {
                    id: 84,
                    label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
                    link: '/ui/cards',
                    parentId: 81
                },
                {
                    id: 85,
                    label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
                    link: '/ui/carousel',
                    parentId: 81
                },
                {
                    id: 86,
                    label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
                    link: '/ui/dropdowns',
                    parentId: 81
                },
                {
                    id: 87,
                    label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
                    link: '/ui/grid',
                    parentId: 81
                },
                {
                    id: 88,
                    label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
                    link: '/ui/images',
                    parentId: 81
                },
                {
                    id: 88,
                    label: 'MENUITEMS.UIELEMENTS.LIST.LIGHTBOX',
                    link: '/ui/lightbox',
                    parentId: 81
                },
                {
                    id: 89,
                    label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
                    link: '/ui/modals',
                    parentId: 81
                },
                {
                    id: 90,
                    label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
                    link: '/ui/rangeslider',
                    parentId: 81
                },
                {
                    id: 91,
                    label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
                    link: '/ui/progressbar',
                    parentId: 81
                },
                {
                    id: 92,
                    label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
                    link: '/ui/sweet-alert',
                    parentId: 81
                },
                {
                    id: 93,
                    label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
                    link: '/ui/tabs-accordions',
                    parentId: 81
                },
                {
                    id: 94,
                    label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
                    link: '/ui/typography',
                    parentId: 81
                },
                {
                    id: 95,
                    label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
                    link: '/ui/video',
                    parentId: 81
                },
                {
                    id: 96,
                    label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
                    link: '/ui/general',
                    parentId: 81
                },
                {
                    id: 97,
                    label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
                    link: '/ui/colors',
                    parentId: 81
                },
                {
                    id: 98,
                    label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
                    link: '/ui/image-crop',
                    parentId: 81
                },
            ]
        },
        {
            id: 99,
            label: 'MENUITEMS.FORMS.TEXT',
            icon: 'bxs-eraser',
            badge: {
                variant: 'danger',
                text: 'MENUITEMS.FORMS.BADGE',
            },
            subItems: [
                {
                    id: 100,
                    label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
                    link: '/form/elements',
                    parentId: 99
                },
                {
                    id: 101,
                    label: 'MENUITEMS.FORMS.LIST.LAYOUTS',
                    link: '/form/layouts',
                    parentId: 99
                },
                {
                    id: 102,
                    label: 'MENUITEMS.FORMS.LIST.VALIDATION',
                    link: '/form/validation',
                    parentId: 99
                },
                {
                    id: 103,
                    label: 'MENUITEMS.FORMS.LIST.ADVANCED',
                    link: '/form/advanced',
                    parentId: 99
                },
                {
                    id: 104,
                    label: 'MENUITEMS.FORMS.LIST.EDITOR',
                    link: '/form/editor',
                    parentId: 99
                },
                {
                    id: 105,
                    label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
                    link: '/form/uploads',
                    parentId: 99
                },
                {
                    id: 106,
                    label: 'MENUITEMS.FORMS.LIST.REPEATER',
                    link: '/form/repeater',
                    parentId: 99
                },
                {
                    id: 107,
                    label: 'MENUITEMS.FORMS.LIST.WIZARD',
                    link: '/form/wizard',
                    parentId: 99
                },
                {
                    id: 108,
                    label: 'MENUITEMS.FORMS.LIST.MASK',
                    link: '/form/mask',
                    parentId: 99
                }
            ]
        },
        {
            id: 109,
            icon: 'bx-list-ul',
            label: 'MENUITEMS.TABLES.TEXT',
            subItems: [
                {
                    id: 110,
                    label: 'MENUITEMS.TABLES.LIST.BASIC',
                    link: '/tables/basic',
                    parentId: 109
                },
                {
                    id: 111,
                    label: 'MENUITEMS.TABLES.LIST.ADVANCED',
                    link: '/tables/advanced',
                    parentId: 109
                }
            ]
        },
        {
            id: 112,
            icon: 'bxs-bar-chart-alt-2',
            label: 'MENUITEMS.CHARTS.TEXT',
            subItems: [
                {
                    id: 113,
                    label: 'MENUITEMS.CHARTS.LIST.APEX',
                    link: '/charts/apex',
                    parentId: 112
                },
                {
                    id: 114,
                    label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
                    link: '/charts/chartjs',
                    parentId: 112
                },
                {
                    id: 115,
                    label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
                    link: '/charts/chartist',
                    parentId: 112
                },
                {
                    id: 116,
                    label: 'MENUITEMS.CHARTS.LIST.ECHART',
                    link: '/charts/echart',
                    parentId: 112
                }
            ]
        },
        {
            id: 117,
            label: 'MENUITEMS.ICONS.TEXT',
            icon: 'bx-aperture',
            subItems: [
                {
                    id: 118,
                    label: 'MENUITEMS.ICONS.LIST.BOXICONS',
                    link: '/icons/boxicons',
                    parentId: 117
                },
                {
                    id: 119,
                    label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
                    link: '/icons/materialdesign',
                    parentId: 117
                },
                {
                    id: 120,
                    label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
                    link: '/icons/dripicons',
                    parentId: 117
                },
                {
                    id: 121,
                    label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
                    link: '/icons/fontawesome',
                    parentId: 117
                },
            ]
        },
        {
            id: 122,
            label: 'MENUITEMS.MAPS.TEXT',
            icon: 'bx-map',
            subItems: [
                {
                    id: 123,
                    label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
                    link: '/maps/google',
                    parentId: 122
                }
            ]
        },
        {
            id: 124,
            label: 'MENUITEMS.MULTILEVEL.TEXT',
            icon: 'bx-share-alt',
            subItems: [
                {
                    id: 125,
                    label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
                    link: '#',
                    parentId: 124
                },
                {
                    id: 126,
                    label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
                    parentId: 124,
                    subItems: [
                        {
                            id: 127,
                            label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
                            parentId: 126,
                        },
                        {
                            id: 128,
                            label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
                            parentId: 126,
                        }
                    ]
                },
            ]
        }
    ];

    submenuitems;
    departmentlist = [];
    dashboardname: any;
    menuone: any = [{
        path: '', title: 'Dashboards', icon: 'zmdi zmdi-view-dashboard', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        { path: '/dashboard/nellore', title: 'Analysis', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/msanalysis', title: 'Mandal/Sachivalayam Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/feedback', title: 'Staff Feed Back', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/supervisor', title: 'Create Supervisor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/volunteerlog', title: 'Staff Logins', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }, 
        { path: '/dashboard/allfamily', title: 'Add Family', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/mappingall', title: 'Cluster Mapping', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        // { path: '/dashboard/instructor', title: 'Add Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/datewisereport', title: 'Date Wise Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/staffwisereport', title: 'Staff Wise Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/surveyreports', title: 'Survey Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        { path: '/dashboard/mappingreports', title: 'Mapping Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/dashboard/household', title: 'House Hold Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        // { path: '/dashboard/mappinginstructor', title: 'Mapping Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        // { path: '/dashboard/stafflogindata', title: 'Staff Punch In Out Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
            // ,
            //     { path: '/dashboard/teachermapping', title: 'Teacher Mapping', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ],
    }];

    menutwo: any = [
        {
            path: '', title: 'Dashboards', icon: 'zmdi zmdi-view-dashboard', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                // { path: '/dashboard/volunteeranalysis', title: 'Analysis', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                // { path: '/dashboard/family', title: 'Create Family', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                // { path: '/dashboard/teacherenrollment', title: 'Volunteer Login', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                // { path: '/dashboard/mapping', title: 'Mapping Family', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/instructor', title: 'Add Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/mappinginstructor', title: 'Mapping Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        }

    ]
    menuthree: any = [
        {
            
            path: '', title: 'Dashboards', icon: 'zmdi zmdi-view-dashboard', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: '/dashboard/staffwisereport', title: 'Staff Wise Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/msanalysis', title: 'Mandal/Sachivalayam Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/volunteerlog', title: 'Staff Logins', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/allfamily', title: 'Add Family', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/mappingall', title: 'Cluster Mapping', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/datewisereport', title: 'Date Wise Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },                
                { path: '/dashboard/surveyreports', title: 'Survey Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                { path: '/dashboard/mappingreports', title: 'Mapping Report', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                // { path: '/dashboard/instructor', title: 'Add Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                // { path: '/dashboard/mappinginstructor', title: 'Mapping Instructor', icon: 'zmdi zmdi-dot-circle-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            ]
        }
    ]
    constructor(public sidebarservice: SidebarService, private router: Router, private loginservice: LoginService, private tappalservice: TappalserviceService) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
            }
            if (
                event instanceof NavigationEnd && typeof window !== 'undefined' && window.innerWidth < 1025 && document?.readyState === 'complete') {
                this.toggleSidebar();
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
                console.log(event.error);
            }
        });
        this.login_type = localStorage.getItem("login_type");
        // console.log(this.login_type, 864);
        this.loginservice.viewlogintype.subscribe((res => {
            this.getSidebarData();
        }))
    }





    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }


    ngOnInit() {
        // this.menuItems = JSON.parse(localStorage.getItem("submenuarr"))   ; 

        // this.submenuitems = [
        //     { path: 'https://live-8796.wati.io/login', title: 'WhatsApp', icon: 'fa fa-whatsapp', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] }
        //  ];    
        let login_type = localStorage.getItem("login_type");
        if (login_type == '0') {
            this.menuItems = [];
            this.menuItems = this.menuone;
        }
        else if (login_type == '1') {
            this.menuItems = [];
            this.menuItems = this.menuthree;
        }
        else {
            this.menuItems = [];
            this.menuItems = this.menutwo;
        }

        // console.log(this.menuItems, 896);

        $.getScript('./assets/js/app-sidebar.js');
    }
    getSidebarData() {
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        let login_type = localStorage.getItem("login_type");
        if (login_type == '0') {
            this.menuItems = [];
            this.menuItems = this.menuone;;
        } else {
            this.menuItems = [];
            this.menuItems = this.menutwo;;
        }
        // console.log(this.login_type, 900);
        // if (this.login_type === 0) {
        //     this.router.navigate(["dashboard/nellore"])
        //     this.router.navigate(["dashboard/msanalysis"])
        //     this.router.navigate(["dashboard/feedback"])
        //     this.router.navigate(["dashboard/volunteerlog"])
        //     this.router.navigate(["dashboard/supervisor"])
        //     this.router.navigate(["dashboard/stafflogindata"])
        // }
        // else if (this.login_type === 1) {
        //     this.router.navigate(["dashboard/family"])
        // }
    }

}
