import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

var TappasApi = 'https://spsnls.in:8527/nodeapp/';

// var TappasApi = 'http://localhost:1405/nodeapp/';

@Injectable({
  providedIn: 'root'
})

export class TappalserviceService {
  token: any;
  constructor(private http: HttpClient, private router: Router) {
    this.token = localStorage.getItem('token');
  }

  getclickdata(data) {
    return this.http.post<any>(TappasApi + `getclickdata`, data)
  }

  gettappaldata(data) {
    return this.http.post<any>(TappasApi + `gettappaldata`, data)
  }

  getanalysis(data) {
    return this.http.post<any>(TappasApi + `getanalysis`, data)
  }

  uploadtappasdatafrommaindashboard(data) {
    return this.http.post<any>(TappasApi + `uploadtappasdatafrommaindashboard`, data)
  }

  tappasdepartments(data) {
    return this.http.post<any>(TappasApi + `tappasdepartments`, data)
  }

  tappscreateusers(data) {
    return this.http.post<any>(TappasApi + `tappscreateusers`, data)
  }

  tappsgetcreatedusers(data) {
    return this.http.post<any>(TappasApi + `tappsgetcreatedusers`, data)
  }

  getEmpList(data) {
    return this.http.post<any>(TappasApi + `getEmpList`, data)
  }

  getMenuList(data) {
    return this.http.post<any>(TappasApi + `getMenuList`, data)
  }

  getuserPermissions(data) {
    return this.http.post<any>(TappasApi + `getuserPermissions`, data)
  }

  sendpermissions(data) {
    return this.http.post<any>(TappasApi + `sendpermissions`, data)
  }
  deletePermissions(data) {
    return this.http.post<any>(TappasApi + `deletePermissions`, data)
  }

  getreportdata(data) {
    return this.http.post<any>(TappasApi + `getreportdata`, data)
  }

  getreportrecords(data) {
    return this.http.post<any>(TappasApi + `getreportrecords`, data)
  }

  gettappalnotassingreportrecords(data) {
    return this.http.post<any>(TappasApi + `gettappalnotassingreportrecords`, data)
  }


  postmultipledata(data) {
    return this.http.post<any>(TappasApi + `postmultipledata`, data)
  }


  postchangtappalofficer(data) {
    return this.http.post<any>(TappasApi + `postchangtappalofficer`, data)
  }


  vehiclepass(data) {
    return this.http.post<any>(`https://itdarampachodavaram.com:3032/nodeapp/vehiclepass`, data)
  }

  addcategory(data) {
    return this.http.post<any>(TappasApi + `addcategory`, data);
  }

  updatemaintappaltable(data) {
    return this.http.post<any>(TappasApi + `updatemaintappaltable`, data);
  }
  getdepartmentlist(data) {
    return this.http.post<any>(TappasApi + `getdepartmentlist`, data);
  }

  edittappasdatafrommaindashboard(data) {
    return this.http.post<any>(TappasApi + `edittappasdatafrommaindashboard`, data);
  }

  gettappaluploadsdata(data) {
    return this.http.post<any>(TappasApi + `gettappaluploadsdata`, data);
  }

  //new start
  tappascnts() {
    return this.http.get<any>(TappasApi + `toatlcntsM`);
  }
  gettappalstatuscnt() {
    return this.http.get<any>(TappasApi + `gettappalastatuscntM`);
  }
  graphsdata() {
    return this.http.get<any>(TappasApi + `graphsdataM`);
  }
  getreport(kmc_data) {
    return this.http.get<any>(TappasApi + `getkmcreportdataM/` + kmc_data);
  }
  mandalwiselliterate() {
    return this.http.get<any>(TappasApi + `mandalwiselliteratereportM`);
  }
  getvolunteereportdata() {
    return this.http.get<any>(TappasApi + `getvolunteereportdataM`);
  }
  getFeedbackreport() {
    return this.http.get<any>(TappasApi + `getmanyamFeedbackRepdata`);
  }
  getmadaldata() {
    return this.http.get<any>(TappasApi + `getManyamMandalData`);
  }
  getvolloginsreportdata(data) {
    return this.http.post<any>(TappasApi + `getVoluntryLoginsData`, data);
  }

  validate_mobile_number(data) {

    return this.http.post<any>(TappasApi + `validate_mobile_number`, data);
  }

  Add_Staff(data) {
    return this.http.post<any>(TappasApi + `Add_Staff`, data);
  }
  getstaffvillages(data) {

    return this.http.post(TappasApi + `getstaffvillages`, data);
  }

  getmappingstaffvillages(mandal) {
    return this.http.get<any>(TappasApi + `getmappingstaffvillages/` + mandal);
  }
  getstaffmandals() {
    return this.http.get<any>(TappasApi + `getstaffmandals`);
  }
  getSecretariatCode(data) {
    return this.http.post<any>(TappasApi + `getSecretariatCode`, data)
  }
  tappascntsmis(data) {
    return this.http.post<any>(TappasApi + `tappascntsmis`, data)
  }
  getTotalDataSurvey(data) {
    return this.http.post<any>(TappasApi + `getTotalDataSurvey`, data)
  }

  getfamilymandals(usr_id) {
    return this.http.get<any>(TappasApi + `getfamilymandals/` + usr_id);
  }

  getfamilysecretariatcode(village) {
    return this.http.get<any>(TappasApi + `getfamilysecretariatcode/` + village);
  }

  getfamilysecretariatname(data) {
    return this.http.post<any>(TappasApi + `getfamilysecretariatname/`, data);
  }

  getfamiliesstaffnames(sect_code) {
    return this.http.post<any>(TappasApi + `getfamiliesstaffnames`, sect_code);
  }

  getstaffnumber(data) {
    return this.http.post<any>(TappasApi + `getstaffnumber`, data);
  }

  getmapstaffnumber(data) {
    return this.http.post<any>(TappasApi + `getmapstaffnumber`, data);
  }

  Add_family(data) {
    return this.http.post<any>(TappasApi + `Add_family`, data)
  }

  getnellorehouseholddata(data) {
    return this.http.post<any>(TappasApi + `getnellorehouseholddata`, data)
  }


  addsupervisor(data) {
    return this.http.post<any>(TappasApi + `addsupervisor`, data)
  }
  getsupervisordata() {
    return this.http.get<any>(TappasApi + `getsupervisordata`);
  }
  getPunchInOutReportData(data) {
    return this.http.post<any>(TappasApi + `getPunchInOutReportData`, data)
  }

  getMappingFamilyData(data) {
    return this.http.post<any>(TappasApi + `getMappingFamilyData`, data)
  }

  UpdateStaffDetailsData(data) {
    return this.http.post<any>(TappasApi + `UpdateStaffDetailsData`, data);

  }
  getMandalwiseCounts() {
    return this.http.get<any>(TappasApi + `getMandalwiseCountsData`);
  }

  getAllMandalsData(data) {
    return this.http.post<any>(TappasApi + `getAllMandalsData`, data);
  }

  AddTeacherForm(data) {
    return this.http.post<any>(TappasApi + `AddTeacherForm`, data);
  }
  getreportTeacherdata(data) {
    return this.http.post<any>(TappasApi + `getreportTeacherdata`, data);
  }
  TeacherMappingData() {
    return this.http.post<any>(TappasApi + `TeacherMappingData`, []);
  }
  getMandalwiseSupervisorwiseCounts(data) {
    return this.http.post<any>(TappasApi + `getMandalwiseSupervisorwiseData`, data)
  }
  getsupervisormandals(data) {
    return this.http.post<any>(TappasApi + `getsupervisormandals`, data)
  }

  // getallstaffmandals(data) {
  //   return this.http.post<any>(TappasApi + `getallsupervisormandals`, data)
  // }

  getMandalwiseSupervisorwiseSachivalayamCounts(data) {
    return this.http.post<any>(TappasApi + `getMandalwiseSupervisorwiseSachivalayamCounts`, data)
  }

  getMandalWiseSecraiteNames(data) {
    return this.http.post<any>(TappasApi + `getMandalWiseSecraiteNames`, data)
  }
  VolunteerAnalysisData() {
    return this.http.post<any>(TappasApi + `VolunteerAnalysisData`, [])
  }


  getstaffnames(sect_code) {
    var data = {
      'sect_code': sect_code
    }
    return this.http.post(TappasApi + `getstaffnames`, data)
  }

  getallstaffnumber(data) {

    return this.http.post<any>(TappasApi + `getallstaffnumber`, data)
  }

  getallnellorehouseholddata1() {
    return this.http.get<any>(TappasApi + `getallnellorehouseholddata1`)
  }

  getmappedfamiliesdata(data) {
    return this.http.post<any>(TappasApi + `getmappedfamiliesdata`, data)
  }
  ///new code for date wise report
  getmappedfamiliesdatadatewise(data) {
    return this.http.post<any>(TappasApi + `getmappedfamiliesdatadatewise`, data)
  }

  getmappedfamiliesdatastaffwise(data) {
    return this.http.post<any>(TappasApi + `getmappedfamiliesdatastaffwise`, data)
  }


  getmappingstaffnames(data) {
   
    return this.http.post<any>(TappasApi + `getmappingstaffnames`, data)
  }

  add_instructor(data) {
    return this.http.post<any>(TappasApi + `add_instructor`, data)
  }

  getinstructordata(data) {
    return this.http.post<any>(TappasApi + `getinstructordata`,data)
  }
  getmappingclusternames(data) {
    return this.http.post<any>(TappasApi + `getmappingclusterdata`,data)
  }

  getmappedfamiliesSuryveyReport(data) {
    return this.http.post<any>(TappasApi + `getmappedfamiliesSuryveyReport`, data)
  }

  getilliteratecounts(data) {
    return this.http.post<any>(TappasApi + `getilliteratecounts`, data)
  }
  getinstructormappeddata(data) {
    return this.http.post<any>(TappasApi + `getinstructormappeddata`, data)
  }

  getoverallilliterate() {
    var data={

    }
    return this.http.post<any>(TappasApi + `getoverallilliterate`,data);
  }
  getoverallilliterateal(data) {
    return this.http.post<any>(TappasApi + `getoverallilliterateal`,data);
  }

  getinstructorbaseddata(instructor) {
    return this.http.get<any>(TappasApi + `getinstructorbaseddata/` + instructor);
  }

  getcluster_ids(data) {
    return this.http.post<any>(TappasApi + `getcluster_ids`, data)
  }

  getclusterbaseddata(cluster_id) {
    
    return this.http.get<any>(TappasApi + `getclusterbaseddata/` + cluster_id);
  }

  getinstructorstaffnumber(data) {
    return this.http.post<any>(TappasApi + `getinstructorstaffnumber`, data);
  }

  UpdateInstructorStaffDetailsData(data) {
    return this.http.post<any>(TappasApi + `UpdateInstructorStaffDetailsData`, data);

  }

  getcardoverallilliterate() {
    var data={

    }
    return this.http.post<any>(TappasApi + `getcardoverallilliterate`,data);
  }

  getoverallnotmapped() {
    var data = {

    }
    return this.http.post<any>(TappasApi + `getoverallnotmapped`, data);
  }

  getcardoverallmapped() {
    var data = {

    }
    return this.http.post<any>(TappasApi + `getcardoverallmapped`, data);
  }

  getSecretariatCodedata(data){
    return this.http.post<any>(TappasApi + `getSecretariatCodedata`, data)
}


//mapping report starts
getcardoverallilliteratereport(data){
  return this.http.post<any>(TappasApi + `getilliteratemappingreport`,data);
};
getcardoverallmappedReport(data){
  return this.http.post<any>(TappasApi + `getmappedilliteratereport`,data);
}
getoverallnotmappedReport(data){
  return this.http.post<any>(TappasApi + `getnotmappedilliteratereport`,data);
}
getInstructorDetails(data){
  return this.http.post<any>(TappasApi + `getInstructorDetails`,data);
}
getOnlyinstructorMandlCount(data){
  return this.http.post<any>(TappasApi + `getOnlyinstructorMandlCount`,data);
}
getallstaffmandals(data) {
  return this.http.post<any>(TappasApi + `getallsupervisormandals`, data)
}

//mapping report ends

getgraphoverallcounts(){
  return this.http.get<any>(TappasApi + `getgraphoverallcounts`);
}
gettodayandyesterdaydata(){
  return this.http.get<any>(TappasApi + `gettodayandyesterdaydata`);
}

gethouseholdreport(){
  return this.http.get<any>(TappasApi + `gethouseholdreport1`);
}

getNewmappedfamiliesFun(data) {
  return this.http.post<any>(TappasApi + `getNewmappedfamiliesdata`, data)
}

updatechangeMobileno(data) {
  return this.http.post<any>(TappasApi + `updatechangeMobilenoData`, data);
}

getNewEmployeesSecFun(data) {
  return this.http.post<any>(TappasApi + `getNewEmployeesSecData`, data);
}

UpdateStaffTransDataFun(data) {
  return this.http.post<any>(TappasApi + `UpdateStaffTransData`, data);
}

deleteStaffMethodData(data) {
  return this.http.post<any>(TappasApi + `deleteStaffMappingData`, data);
}


}
